import { i18n } from "@/modules/App/components/i18n/i18n.client"
import { Pet, PetHealth, PetHealthNotHealthy } from "@/shared/types/global-store"
import { IS_DEFFERED_SUBSCRIPTION_ACTIVE } from "@/shared/utils/flag-features"
import { capitalizeFirstLetter } from "@/shared/utils/string"
import { trackMeetingClickPetBirthdate } from "@/shared/utils/tracking"
import {
  differenceInMonths,
  differenceInYears,
  format,
  formatDuration,
  intervalToDuration,
  isAfter,
  parse,
} from "date-fns"
import frLocale from "date-fns/locale/fr"
import { Trans } from "react-i18next"

import { BookingComp } from "../../../shared/components/Booking/booking"

export function generateMonthsOptions(local = "fr-FR") {
  return Array.from({ length: 12 }, (_, index) => {
    return {
      label: capitalizeFirstLetter(new Date(0, index).toLocaleString(local, { month: "long" })),
      value: String(index + 1),
    }
  })
}

export function generateYearsOptions() {
  const currentYear = new Date().getFullYear()
  // TODO: change this, I add it ramdomly
  const startYear = 2003

  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) =>
    (startYear + index).toString()
  )

  return years
    .map((year) => ({
      label: year,
      value: year,
    }))
    .reverse()
}

export function getPetBirthday(birthMonth: string, birthYear: string) {
  const birthDate = parse(`${birthMonth}-01-${birthYear}`, "M-d-yyyy", new Date())
  const formattedBirthDate = format(birthDate, "yyyy-MM-dd")

  return { birthDate, formattedBirthDate }
}

export const calculateAgeInYears = (birthDate: Date, currentDate: Date): number => {
  return differenceInYears(currentDate, birthDate)
}

export const calculateAgeInMonths = (birthDate: Date, currentDate: Date): number => {
  return differenceInMonths(currentDate, birthDate)
}

export const isPetTooYoung = (birthDate: Date, currentDate: Date): boolean | undefined => {
  const ageInYears = calculateAgeInYears(birthDate, currentDate)
  const ageInMonths = calculateAgeInMonths(birthDate, currentDate)

  return ageInYears === 0 && ageInMonths < 2
}

export const isPetTooOld = (birthDate: Date, currentDate: Date): boolean | undefined => {
  const ageInYears = calculateAgeInYears(birthDate, currentDate)

  return ageInYears > 8
}

export const checkIsAfterPetBirthDate = (birthMonth: string, birthYear: string): boolean => {
  const { birthDate } = getPetBirthday(birthMonth, birthYear)
  const currentDate = new Date()
  return isAfter(birthDate, currentDate)
}

export const matchIsValidDate = (
  birthMonth: string,
  birthYear: string
): "idle" | "valid" | "invalid" | "valid-with-pet-young" => {
  if (!birthMonth || !birthYear) {
    return "idle"
  }
  const currentDate = new Date()
  const { birthDate } = getPetBirthday(birthMonth, birthYear)

  if (checkIsAfterPetBirthDate(birthMonth, birthYear)) {
    return "invalid"
  }

  if (isPetTooOld(birthDate, currentDate)) {
    return "invalid"
  }

  if (isPetTooYoung(birthDate, currentDate)) {
    return IS_DEFFERED_SUBSCRIPTION_ACTIVE ? "valid-with-pet-young" : "invalid"
  }

  return "valid"
}

export function getBithdateInfoCardSubtitle(
  birthMonth: string,
  birthYear: string,
  petSex: Pet["sex"],
  petName: Pet["name"]
) {
  const { birthDate } = getPetBirthday(birthMonth, birthYear)
  const currentDate = new Date()

  if (checkIsAfterPetBirthDate(birthMonth, birthYear)) {
    return i18n.t(`pet-birthdate-errors.future-birthdate`, {
      ns: "pet-birthdate-page",
    })
  }

  if (isPetTooYoung(birthDate, currentDate)) {
    return (
      <Trans
        i18n={i18n}
        ns="pet-birthdate-page"
        i18nKey={
          IS_DEFFERED_SUBSCRIPTION_ACTIVE
            ? "pet-birthdate-errors.pet-is-too-young"
            : "pet-birthdate-errors.pet-is-too-young-error"
        }
        values={{
          pet_name: petName,
          eventual_start_date: format(
            new Date(birthDate.setMonth(birthDate.getMonth() + 2)),
            "dd/MM/yyyy"
          ),
        }}
        components={{
          drawer: <BookingComp onClick={trackMeetingClickPetBirthdate} />,
        }}
      />
    )
  }

  if (isPetTooOld(birthDate, currentDate)) {
    return i18n.t(`pet-birthdate-errors.pet-is-too-old`, {
      ns: "pet-birthdate-page",
    })
  }

  return i18n.t(`pet-birthdate-info-card-subtitle-${petSex.toLowerCase()}`, {
    ns: "pet-birthdate-page",
    pet_name: petName,
  })
}

export function parsePetBirthday(date: string): Date {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date())
  parsedDate.setHours(0, 0, 0, 0)
  parsedDate.setDate(1)
  return parsedDate
}

export function getYearAndMonth(birthMonth: string, birthYear: string): string {
  const currentDate = new Date()
  currentDate.setDate(1)

  const birthDate = parse(`${birthMonth}-01-${birthYear}`, "M-d-yyyy", currentDate)

  return formatDuration(
    intervalToDuration({
      start: birthDate,
      end: currentDate,
    }),
    {
      format: ["years", "months"],
      locale: frLocale,
      delimiter: " et ",
    }
  )
}

export function getYearAndMonthLabel(
  birthMonth: string,
  birthYear: string,
  petSex: Pet["sex"]
): string {
  const formattedYearAndMonthLabel = i18n.t(
    `pet-birthdate-info-card-title-${petSex.toLowerCase()}`,
    {
      ns: "pet-birthdate-page",
      age: getYearAndMonth(birthMonth, birthYear),
    }
  )

  return formattedYearAndMonthLabel
}

export function matchIsPetNotHealthy(currentPet?: PetHealth): currentPet is PetHealthNotHealthy {
  return Boolean(currentPet?.status === "not_healthy")
}

export function getBreed(race?: string, label?: string) {
  if (race === "mixed_breed") {
    return i18n.t("unknown-dog-breed", {
      ns: "tribe-page",
    })
  }

  if (race === "europeen") {
    return i18n.t("unknown-cat-breed", {
      ns: "tribe-page",
    })
  }

  return label
}
const petOrder: string[] = i18n.t("pet-order", {
  ns: "pet-name-page",
  returnObjects: true,
})

export function getPetOrder(index: number) {
  if (index >= 1 && index <= petOrder.length) {
    return petOrder[index - 1]
  }

  return `${index + 1}${i18n.t("th", {
    ns: "pet-name-page",
  })}`
}
